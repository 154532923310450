import { useEffect, useState } from "react"
import "./PopularVehicles.css"
import axios from "axios"
import { Link } from "react-router-dom/cjs/react-router-dom"

function formatNumberWithCommas(number) {
    return number.toLocaleString('en-US');
}

function convertMilliseconds(ms) {
  // Constants for time conversions
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const millisecondsInAnHour = 60 * 60 * 1000;
  const millisecondsInAMinute = 60 * 1000;

  // Calculate days
  const days = Math.floor(ms / millisecondsInADay);
  ms %= millisecondsInADay;

  // Calculate hours
  const hours = Math.floor(ms / millisecondsInAnHour);
  ms %= millisecondsInAnHour;

  // Calculate minutes
  const minutes = Math.floor(ms / millisecondsInAMinute);

  return { days, hours, minutes };
}

const PopularVehicles = () => {
    const [data, setData] = useState([]);
    const [carouselCount, setCarouselCount] = useState(0);

    useEffect(() => {
        axios.post(`/api/auction/copart/recomended-lots`)
            .then((res) => {
                setData([...res.data.data]);
            })
            .catch(() => {

            })
    }, [])

    const handlers = {
        nextCarouselSlide (){
            setCarouselCount((prev) => {
                const nextSlide = prev < data.length - 4 ? prev + 1 : 0;
                return nextSlide
            })
        },
        prevCarouselSlide (){
            setCarouselCount((prev) => {
                const prevSlide = prev != 0 ? prev - 1 : data.length - 4;
                return prevSlide
            })
        }
    }

    return(
        <div className="popular-vehicle-container">
            <div className="vehicle-type-selector-head">
                <h3 className="vehicle-type-selector-title">პოპულარული მანქანები</h3>
                <Link className={"view-all-button"} to="/catalogue">ყველას ნახვა</Link>
            </div>
            <div className="main-page-recomended-lots-container">
                <div className="main-page-recomended-lots-carousel" style={{marginLeft: `${carouselCount * -356}px`}}>
                    {
                        data.length > 0 && (
                            data.map((car) => {
                                return (
                                    <Link className="cars-items-card-box" key={car.lotNumber} to={`/vehicle/${car.lotNumber}`} >
                                        <div className="vehicle-card-thumbnail" style={{backgroundImage: `url('${car.imageUrl}')`}}>
                                        </div>
                                        <div className="vehicle-card-details-box">
                                        <h6>{car.mark} {car.model}</h6>
                                        <p className="vehicle-card-car-name">{car.lotDesc}</p>
                                        <p className="vehicle-card-lot">LOT#: {car.lotNumber}</p>
                                        <div className="vehicle-card-amount-content">
                                            <div className="vehicle-card-current-bid">
                                            <p className="vehicle-card-current-bid-title">მიმდინარე ფასი </p>
                                            <p className="vehicle-card-current-bid-amount">{formatNumberWithCommas(car.currentHighBid)} $</p>
                                            </div>
                                            <div className="vehicle-card-buy-now-price">
                                            <p className="vehicle-card-buy-now-title">ახლავე ყიდვა </p>
                                            <p className="vehicle-card-buy-now-amount">{formatNumberWithCommas(car.currentHighBid)} $</p>
                                            </div>
                                        </div>
                                        <div className="vehicle-card-car-details-hr">
                                            <div className="vehicle-card-car-details-hr-val">
                                            <img className="vehicle-card-car-details-hr-icon" src="/static/icons/millage.svg" alt="millage" />
                                            <div className="vehicle-card-car-details-hr-value">
                                                <p>{`${car.odometer} მ`}</p>
                                            </div>
                                            </div>
                                            <div className="vehicle-card-car-details-hr-val">
                                            <img className="vehicle-card-car-details-hr-icon" src="/static/icons/fuel.svg" alt="fuel" />
                                            <div className="vehicle-card-car-details-hr-value">
                                                <p>{car.fuelType}</p>
                                            </div>
                                            </div>
                                            <div className="vehicle-card-car-details-hr-val">
                                            <img className="vehicle-card-car-details-hr-icon" src="/static/icons/transmission.svg" alt="transmission" />
                                            <div className="vehicle-card-car-details-hr-value">
                                                <p>{car.transmission}</p>
                                            </div>
                                            </div>
                                        </div>
                                        <hr className="vehicle-card-hr"/>
                                        <div className="vehicle-card-auction-info">
                                            <div className="vehicle-card-auction-date">
                                            <img src="/static/icons/clock.svg" alt="clock"/>
                                            {/* <span>{time_left.days} დღე, {time_left.hours} სთ, {time_left.minutes}წთ</span> */}
                                            </div>
                                            <div className="vehicle-card-viewers">
                                            <img src="/static/icons/eye.svg" alt="eye"/>
                                            <span>1 ნახულობს</span>
                                            </div>
                                        </div>
                                        </div>
                                    </Link>
                                )
                            })
                        )
                    }
                </div>
            </div>
            <div className="main-page-recomended-lot-slider-buttons">
                <button onClick={handlers.prevCarouselSlide} className="main-page-recomended-lot-slider-button" style={{marginRight: "24px"}}><img src="/static/icons/next-slider-black.svg" alt="arrow" style={{transform: "rotate(180deg)"}} /></button>
                <button onClick={handlers.nextCarouselSlide} className="main-page-recomended-lot-slider-button"><img src="/static/icons/next-slider-black.svg" alt="arrow" /></button>
            </div>
        </div>
    )
}

export default PopularVehicles