import { useEffect } from "react"
import "./CalculatorDropdown.css"

const CalculatorDropdown = ({label, defaultValue, listValue, setter, selected, shownDropdown, setShownDropdown}) => {

    return(
        <div className="calculator-dropdown-container">
            <div className="calculator-dropdown-select-box" onClick={() => setShownDropdown()}>
                <p className="calculator-dropdown-label">{label}</p>
                <div className="calculator-dropdown-selected-value">
                    <p className="calculator-dropdown-active-value">{selected == "" ? defaultValue : selected}</p>
                    <img src="/static/icons/down-icon.svg" alt="down"/>
                </div>
            </div>
            {shownDropdown && <div className="calculator-dropdown-list-box">
                {listValue.length == 0 ? <p className="records-not-found">ჩანაწერი ვერ მოიძებნა</p> : (
                    listValue.map((item) => {
                        return <p onClick={() => {
                            setter(item.name);
                            setShownDropdown();
                        }} className="calculator-dropdown-item">{item.name}</p>
                    })
                )}
            </div>}
        </div>
    )
}

export default CalculatorDropdown