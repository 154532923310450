import { Link } from "react-router-dom/cjs/react-router-dom"
import "./Footer.css"
import { useState } from "react"

const Footer = () => {
    const [subscriber, setSubscriber] = useState("");

    return(
        <div className="footer-container">
            <div className="footer-head">
                <Link><img src="/static/icons/logo-white.png"/></Link>
                <div className="subscribe-news-footer">
                    <input className="subscribe-news-input" value={subscriber} placeholder="შეიყვანეთ თქვენი ელ-ფოსტა" onChange={(e) => setSubscriber(e.target.value)}/>
                    <button className="subscribe-news-button">გამოწერა</button>
                </div>
            </div>
            <div className="footer-hr"></div>
            <div className="footer-nav-container">
                <div className="footer-nav-column-box">
                    <h5 className="footer-nav-title">კომპანია</h5>
                    <ul className="footer-nav-column">
                        <li><Link to="/">ჩვენ შესახებ</Link></li>
                        <li><Link to="/">კარიერა</Link></li>
                        <li><Link to="/">ბლოგი</Link></li>
                        <li><Link to="/">FAQs</Link></li>
                        <li><Link to="/">კონტაქტი</Link></li>
                    </ul>
                </div>
                <div className="footer-nav-column-box">
                    <h5 className="footer-nav-title">დახმარება</h5>
                    <ul className="footer-nav-column">
                        <li><Link to="/">მესიჯის მიწერა</Link></li>
                        <li><Link to="/">ზარის მოთხოვნა</Link></li>
                        <li><Link to="/">+ 032 211 11 22</Link></li>
                        <li><Link to="/">Info@Carspace.ge</Link></li>
                    </ul>
                </div>
                <div className="footer-nav-column-box">
                    <h5 className="footer-nav-title">ბრენდები</h5>
                    <ul className="footer-nav-column">
                        <li><Link to="/">Aston Martin</Link></li>
                        <li><Link to="/">Audi</Link></li>
                        <li><Link to="/">Bentley</Link></li>
                        <li><Link to="/">BMW</Link></li>
                        <li><Link to="/">Bugatti</Link></li>
                        <li><Link to="/">Ferrari</Link></li>
                        <li><Link to="/">FAQs</Link></li>
                        <li><Link to="/">Lamborghini</Link></li>
                    </ul>
                </div>
                <div className="footer-nav-column-box">
                    <h5 className="footer-nav-title">კატეგორიები</h5>
                    <ul className="footer-nav-column">
                        <li><Link to="/">კუპე</Link></li>
                        <li><Link to="/">სედანი</Link></li>
                        <li><Link to="/">უნივერსალი</Link></li>
                        <li><Link to="/">ჯიპი</Link></li>
                        <li><Link to="/">მინივენი</Link></li>
                        <li><Link to="/">ფურგონი</Link></li>
                        <li><Link to="/">კაბრიოლეტი</Link></li>
                        <li><Link to="/">ლიმუზინი</Link></li>
                    </ul>
                </div>
                <div className="footer-nav-column-box">
                    <h5 className="footer-nav-title">მობილური აპლიკაცია</h5>
                    <ul className="footer-nav-column apps-list">
                        <li><a href="/">
                            <svg style={{marginRight: "12px"}} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M15.1869 9.33237C14.0231 9.33237 12.2217 8.02995 10.3246 8.07703C7.82166 8.10841 5.526 9.50498 4.23469 11.7175C1.63612 16.1583 3.56512 22.7175 6.09991 26.3267C7.34339 28.0841 8.81007 30.0613 10.755 29.9985C12.6202 29.9201 13.3217 28.806 15.5855 28.806C17.8333 28.806 18.471 29.9985 20.4478 29.9515C22.4565 29.9201 23.7319 28.1626 24.9594 26.3894C26.3783 24.3495 26.9681 22.3723 27 22.2625C26.9522 22.2468 23.0942 20.7874 23.0464 16.3937C23.0145 12.7218 26.0913 10.9643 26.2348 10.8859C24.4811 8.35948 21.7869 8.07703 20.8463 8.01426C18.3913 7.82596 16.3347 9.33237 15.1869 9.33237ZM19.3319 5.6291C20.3681 4.40513 21.0536 2.69472 20.8623 1C19.3797 1.06277 17.5942 1.97289 16.526 3.19686C15.5695 4.2796 14.7405 6.02139 14.9637 7.68473C16.6058 7.81026 18.2956 6.85306 19.3319 5.6291Z" fill="white"/>
                            </svg>
                            <div className="mobile-app-btns">
                                <span className="mobile-app-btn-text-12">გადმოწერე</span>
                                <span className="mobile-app-btn-text-14">App Store</span>
                            </div>
                        </a></li>
                        <li style={{marginTop: "12px"}}><a href="/">
                            <svg style={{marginRight: "12px"}} xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 28 32" fill="none">
                                <path d="M27.0169 17.7304L21.9249 20.6877L17.3577 16.0304L21.9613 11.3357L27.0169 14.2717C27.3153 14.4448 27.5636 14.6962 27.7363 15C27.909 15.3038 28 15.6493 28 16.001C28 16.3528 27.909 16.6982 27.7363 17.0021C27.5636 17.3059 27.3153 17.5572 27.0169 17.7304ZM0.145525 1.2317C0.0495523 1.47205 0.000123584 1.72929 0 1.98903V30.0117C0 30.301 0.0584697 30.5704 0.161117 30.8117L14.6551 16.029L0.145525 1.2317ZM16.0064 14.6517L20.2396 10.3344L2.89101 0.259701C2.51901 0.0408113 2.08605 -0.0432554 1.66184 0.0210348L16.0064 14.6517ZM16.0064 17.4077L1.71381 31.985C2.10101 32.033 2.509 31.9637 2.89101 31.741L20.2033 21.6877L16.0064 17.4077Z" fill="white"/>
                            </svg>
                            <div className="mobile-app-btns">
                                <span className="mobile-app-btn-text-12">გადმოწერე</span>
                                <span className="mobile-app-btn-text-14">Play Store</span>
                            </div>
                        </a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-hr-100"></div>
            <div className="footer-bottom-block">
                <p>© 2024 Carspace.ge</p>
                <div className="faqs-and-terms">
                    <Link to="/terms-and-conditions">წესები & პირობები</Link>
                    <div className="footer-divider-circle"></div>
                    <Link to="/privacy-policy">უსაფრთხოების პოლიტიკა</Link>
                </div>
            </div>
        </div>
    )
}

export default Footer