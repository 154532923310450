/* eslint-disable */
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import './App.css';
import {Link} from 'react-router-dom';
import Header from './common/Header/Header';
import Catalogue from './pages/Catalogue';
import VehicleDetails from './pages/VehicleDetails';
import { useState } from 'react';
import MainPage from './pages/MainPage';
import AboutUs from './pages/AboutUs';
import TransportationCalculator from './components/MainPage/TransportationCalculator/TransportationCalculator';
import Contact from './pages/Contact';
import NotFound from './pages/notFound';
import Footer from './common/Footer/Footer';
function App() {
    const [selectedLot, setSelectedLot] = useState("");
    return (
        <div className="App">
            <Header />
            <Switch>
                <Route exact path='/'><MainPage /></Route>
                <Route exact path='/catalogue'><Catalogue setSelectedLot={setSelectedLot} /></Route>
                <Route exact path='/vehicle/:lot'><VehicleDetails currentLot={selectedLot} /></Route>
                <Route exact path='/about-us'><AboutUs /></Route>
                <Route exact path='/contact'><Contact /></Route>
                <Route exact path='/calculator'>
                    <div className='transportation-calculator-page'>
                        <div className="about-us-page-map">
                            <Link to="/main">მთავარი</Link>
                            <span>/</span>
                            <Link to="/calculator">კალკულატორი</Link>
                        </div>
                        <h3 className='about-us-title contact-page-title'>კალკულატორი </h3>
                        <TransportationCalculator />
                    </div>
                </Route>
                <Route path='/*'><NotFound /></Route>

            </Switch>
            <Footer/>
        </div>
    );
}

export default App;
